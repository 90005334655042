import request from '../utils/request'

export function getAnyList(data) {
  return request({
    url: '/any/any_info/list',
    method: 'post',
    data: data,
    sync: true
  })
}

export function saveAny(data) {
  return request({
    url: 'any/any_info/saveAny',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

export function saveAny2(data) {
  return request({
    url: 'any/any_info/saveAny3',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

export function login(data) {
  return request({
    url: 'any/member/login',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

export function reg(data) {
  return request({
    url: 'any/member/reg',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

export function getUserInfo(data) {
  return request({
    url: 'any/member/getById',
    method: 'GET',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

export function saveCache(data) {
  console.log("保存缓存数据",data)
  return request({
    url: 'any/any_info/saveCache',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}
export function getCache() {
  return request({
    url: 'any/any_info/getCache',
    method: 'GET',
    sync: true,
    type: 'application/json'
  })
}

export function updateUser(data) {
  return request({
    url: 'any/member/updateUser2',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

  export function hi(data) {
    return request({
      url: 'any/person',
      method: 'GET',
      data: data,
      sync: true,
      type: 'application/json'
    })
  }



export function saveMilk(data) {
  return request({
    url: 'any/milk/save',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

export function milkList(data) {
  return request({
    url: 'any/milk/list',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

export function getValidList(data) {
  return request({
    url: 'any/validityPeriod/list',
    method: 'post',
    data: data,
    sync: true,
    type: 'application/json'
  })
}

